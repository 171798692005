export enum TestType {
  Measurement, //가장 기본.
  Question, //Question로 measurement를 만듦.
  One_Page, //category가 한페이지로 이루어짐 (SNB 없음)
}

export const Tests: { [key: string]: TestType } = {
  '001:001:002': TestType.Measurement, // 최대모음연장발성
  '001:001:003': TestType.Measurement, // 조음길항운동
  '001:001:004': TestType.Measurement, // 표준문단읽기
  '001:001:008': TestType.Measurement, // 아리랑
  '002:002:001': TestType.Measurement, // 조음길항운동
  '001:001:006': TestType.Question, // 단어모음
  '002:002:003': TestType.Measurement, // 음절증가
  '001:001:007': TestType.Question, //단어자음
  '002:001:002': TestType.Question, // 상지 실행증 도구 사용
  '002:001:001': TestType.Question, // 상지 실행증 도구 미사용
  '002:001:003': TestType.Question, // 구강 안면 실행증
  '001:001:005': TestType.Question, //문장띄어읽기
  '003:001:001': TestType.Question, // 말장애  자가인식
  '003:002:001': TestType.Question, // 말장애지수
  '003:003:001': TestType.Question, // BISA15+,
  '002:002:002': TestType.Measurement, // 말장애 - 조음길항운동
  '001:001:001': TestType.One_Page, // 자발화 및 말 자가인식
  '001:002:001': TestType.One_Page, //얼굴 및 입술
  '001:002:002': TestType.One_Page, // 턱
  '001:002:003': TestType.One_Page, // 혀
  '001:002:004': TestType.One_Page, // 연구개
  '001:002:005': TestType.One_Page, // 호흡기 및 후두
  '001:002:006': TestType.One_Page, // 삼킴
};
