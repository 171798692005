import Pitchfinder from 'pitchfinder';
import { useRef, useState } from 'react';

const analyzePitch = ({
  peaks,
  sampleRate = 44100,
  algo = 'AMDF',
}: {
  peaks: Float32Array; // 오디오 데이터
  sampleRate?: number; // 샘플링 레이트
  algo?: string; // 분석 알고리즘
}) => {
  const detectPitch = Pitchfinder[algo]({ sampleRate });
  const duration = peaks.length / sampleRate;
  const bpm = peaks.length / duration / 60;

  const frequencies = Pitchfinder.frequencies(detectPitch, peaks, {
    tempo: bpm,
    quantization: bpm,
  });

  // 최소 및 최대 주파수 계산
  const validFrequencies = frequencies.filter((f) => f != null) ?? [];
  const minFrequency = validFrequencies?.length ? Math.min(...validFrequencies) : null;
  const maxFrequency = validFrequencies?.length ? Math.max(...validFrequencies) : null;

  return {
    frequencies,
    baseFrequency: validFrequencies[0] || 0, // 첫 번째 유효 주파수를 기본값으로
    minFrequency,
    maxFrequency,
  };
};

export const usePitchContour = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [frequency, setFrequency] = useState<null | {
    min: string;
    max: string;
  }>(null);

  const rendered = () => (
    <canvas
      id="dddd"
      ref={canvasRef}
      style={{
        zIndex: 99,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
      }}
    />
  );

  const renderCanvas = ({
    peaks,
    sampleRate,
    width,
    height,
  }: {
    peaks: Float32Array;
    sampleRate: number;
    width: number;
    height: number;
  }) => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    const { maxFrequency, minFrequency, frequencies } = analyzePitch({
      peaks,
      sampleRate,
    });
    if (minFrequency && maxFrequency) {
      setFrequency({
        min: minFrequency.toFixed(2),
        max: maxFrequency.toFixed(2),
      });
      // Canvas 크기 설정
      canvas.width = width;
      canvas.height = height;

      // 색상 및 스타일 설정
      const pitchContourColor = 'rgba(0, 123, 255, 0.9)'; // 밝은 파란색으로 변경
      const lineThickness = 2;
      const pitchPointColor = 'rgba(194, 99, 81, 0.8)'; // 점 색상

      // y축 눈금 그리기
      ctx.clearRect(0, 0, canvas.width, canvas.height); // 이전 내용을 지우기
      ctx.fillStyle = '#666';
      ctx.font = '12px Arial';
      ctx.fillText(maxFrequency ? `${Math.round(maxFrequency)} Hz` : '', 5, 10);
      ctx.fillText(minFrequency ? `${Math.round(minFrequency)} Hz` : '', 5, height - 5);
      // 선형 스케일의 y좌표 계산 함수
      const frequencyToY = (frequency: number) => {
        return Math.round(
          height - ((frequency - minFrequency) / (maxFrequency - minFrequency)) * height,
        );
      };

      // pitch contour 그리기
      ctx.beginPath();
      let prevY = frequencyToY(frequencies?.at(0) ?? 0);
      ctx.moveTo(0, prevY);

      frequencies.forEach((frequency, index) => {
        if (!frequency) return;
        const y = frequencyToY(frequency);
        ctx.lineTo(index * (width / frequencies.length), y); // X 좌표 비율 맞추기

        ctx.fillStyle = pitchPointColor;
        ctx.fillRect(index, y, 2, 2); // pitch 점 찍기

        prevY = y;
      });

      // 선 색상 설정 및 그리기
      ctx.strokeStyle = pitchContourColor; // 밝은 색으로 설정
      ctx.lineWidth = lineThickness; // 굵기 설정
      ctx.stroke();
    }
  };

  const clearCanvas = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // 캔버스 초기화
      }
    }
    setFrequency(null);
  };
  return {
    renderCanvas,
    rendered,
    clearCanvas,
    frequency,
  };
};
