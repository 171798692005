import { styled, Typography } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions';
import { Button, IconButton as MuiIconButton } from '@mui/material';
import { IconRecording, IconPause, IconPlay, IconPlaying } from '@core/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { testSelector } from '@src/recoils/test';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useGetAudio } from '@src/modules/patientTest';
import { Box, Stack } from '@mui/material';
import { Timer } from '../Timer';
import { useParams } from 'react-router-dom';
import { usePatient } from '@src/modules/patient';
import { usePitchContour } from './usePitchContour';

const Wrap = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.color.grey[50]};
  min-height: 52px;
  border-radius: 88px;
  padding: 16px 40px;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
`;

const ControlWrap = styled(Stack)`
  gap: 16px;
  margin-right: 16px;
  justify-content: space-between;
  flex-direction: row;
`;

const IconButton = styled(MuiIconButton)`
  padding: 0;
  width: 32px;
  height: 32px;
`;

const WaveContainer = styled('div')`
  width: 100%;
`;

const minValue = 0.01;
const minSilenceDuration = 0.01;
const mergeDuration = 0.02;
export const Recorder = ({
  categoryCode,
  isDetail,
  usePitch,
}: {
  categoryCode: string;
  isDetail?: boolean;
  usePitch?: boolean;
}) => {
  const waveRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const recordRef = useRef<RecordPlugin | null>(null);
  const regionsRef = useRef<RegionsPlugin>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [timeCount, setTimeCount] = useState(0);
  const [count, setCount] = useState(0);

  const { renderCanvas, rendered, clearCanvas, frequency } = usePitchContour();
  const { patientId } = useParams();

  const { data: patient } = usePatient(Number(patientId));

  const [test, setTest] = useRecoilState(testSelector(categoryCode));
  const isRecording = recordRef.current && recordRef.current.isRecording();
  const { data: audio } = useGetAudio(test?.voiceRecordingUrl ?? '', {
    enabled: !!test?.voiceRecordingUrl,
  });

  const extractRegions = useCallback(
    (audioData: Float32Array, duration: number) => {
      const scale = duration / audioData.length;
      const silentRegions = [];

      // Find all silent regions longer than minSilenceDuration
      let start = 0;
      let end = 0;
      let isSilent = false;
      for (let i = 0; i < audioData.length; i++) {
        if (audioData[i] < minValue) {
          if (!isSilent) {
            start = i;
            isSilent = true;
          }
        } else if (isSilent) {
          end = i;
          isSilent = false;
          if (scale * (end - start) > minSilenceDuration) {
            silentRegions.push({
              start: scale * start,
              end: scale * end,
            });
          }
        }

        if (i === audioData?.length - 1 && isSilent) {
          end = i;
          isSilent = false;
          if (scale * (end - start) > minSilenceDuration) {
            silentRegions.push({
              start: scale * start,
              end: scale * end,
            });
          }
        }
      }

      // Merge silent regions that are close together
      const mergedRegions: { start: number; end: number }[] = [];
      silentRegions.forEach((region, index) => {
        if (
          mergedRegions.length &&
          region.start - mergedRegions[mergedRegions.length - 1].end < mergeDuration
        ) {
          mergedRegions[mergedRegions.length - 1].end = region.end;
        } else {
          mergedRegions.push(region);
        }
      });

      // Find regions that are not silent
      const regions: { start: number; end: number }[] = [];
      let lastEnd = 0;
      mergedRegions.forEach((region) => {
        if (region.start) {
          regions.push({
            start: lastEnd,
            end: region.start,
          });
        }

        lastEnd = region.end;
      });

      return regions;
    },
    [minSilenceDuration, mergeDuration, minValue],
  );

  const initWaveSurfer = (container: HTMLElement, url?: string) => {
    waveSurferRef.current = WaveSurfer.create({
      progressColor: '#5C7CFA',
      waveColor: '#FA5252',
      barHeight: 2,
      cursorWidth: 0,
      height: 56,
      minPxPerSec: 50,
      interact: true,
      container,
      url,
    });

    regionsRef.current = waveSurferRef.current.registerPlugin(RegionsPlugin.create());

    recordRef.current = waveSurferRef.current.registerPlugin(RecordPlugin.create());

    recordRef.current.on('record-progress', (time) => {
      setTimeCount(Number((time / 10)?.toFixed()));
      if (categoryCode.startsWith('001:001:003') && time >= 5100) {
        recordRef?.current?.stopRecording();
      }
    });

    let durationLoaded = false;
    if (recordRef.current) {
      recordRef.current;
      recordRef.current.on('record-end', setAudioFile);
    }
    if (waveSurferRef.current) {
      waveSurferRef.current.on('ready', () => {
        if (
          !durationLoaded &&
          waveSurferRef.current &&
          !isRecording &&
          waveSurferRef.current.getMediaElement().src
        ) {
          durationLoaded = true;
          const duration = waveSurferRef.current.getDuration();
          setTimeCount(Math.round(duration * 100));
        }
      });

      waveSurferRef.current.on('finish', () => {
        setIsPlaying(false);
      });

      let activeRegion: Region | null = null;
      if (regionsRef.current) {
        regionsRef.current.on('region-clicked', (region, e) => {
          e.stopPropagation();
          region.play();
          activeRegion = region;
        });
      }

      waveSurferRef.current.on('timeupdate', (currentTime) => {
        // When the end of the region is reached
        if (activeRegion && currentTime >= activeRegion.end) {
          // Stop playing
          if (waveSurferRef.current) {
            waveSurferRef.current.pause();
            activeRegion = null;
          }
        }
      });
    }
  };
  useEffect(() => {
    if (waveRef.current) {
      initWaveSurfer(waveRef.current);
    }

    return () => {
      waveSurferRef?.current?.destroy();
      waveSurferRef.current = null;

      recordRef?.current?.destroy();
      recordRef.current = null;
    };
  }, []);

  const setAudioFile = useCallback(
    async (blob: Blob) => {
      if (waveSurferRef.current) {
        waveSurferRef.current.on('decode', (duration) => {
          // Create regions for each non-silent part of the audio
          if (regionsRef.current && waveSurferRef.current) {
            regionsRef.current.clearRegions();
            const decodedData = waveSurferRef.current.getDecodedData();

            if (waveRef?.current && decodedData && !recordRef.current?.isRecording()) {
              const peaks = decodedData.getChannelData(0);
              if (usePitch) {
                renderCanvas({
                  peaks,
                  sampleRate: 8000,
                  width: waveRef.current?.offsetWidth,
                  height: waveRef.current?.offsetHeight,
                });
              }

              const regions = extractRegions(decodedData.getChannelData(0), duration);

              if (blob) {
                setTimeCount(Number((decodedData?.duration * 100)?.toFixed()));
                setTest({
                  audio: blob,
                  testCategoryCode: categoryCode,
                  duration: decodedData?.duration,
                  regions: regions?.length,
                });
              }
              if (recordRef.current) {
                regions.forEach((region, index) => {
                  if (regionsRef.current) {
                    regionsRef.current.addRegion({
                      start: region.start,
                      end: region.end,
                      content: (index + 1).toString(),
                      drag: false,
                      resize: false,
                    });
                  }
                });
              }
            }
          }
        });
      }

      setIsRecordingPaused(false);
    },
    [setTest, extractRegions, categoryCode, usePitch, renderCanvas],
  );

  const handleRecordPause = useCallback(() => {
    if (recordRef.current) {
      if (!recordRef.current.isPaused()) {
        recordRef.current.pauseRecording();
        setIsRecordingPaused(true);
      }
    }
  }, [setIsRecordingPaused]);

  const handlePlay = useCallback(() => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
      setIsPlaying((prev) => !prev);
    }
  }, [setIsPlaying]);

  const handleChangeCount = useCallback(
    (type: 'reset' | 'add' | 'sub') => {
      const newValue = type === 'reset' ? 0 : type === 'add' ? count + 1 : count - 1;
      setCount(newValue);
    },
    [count, setCount],
  );

  const handleRecordStop = useCallback(() => {
    if (recordRef?.current) {
      recordRef.current.stopRecording();
    }
  }, []);

  const handleRecord = useCallback(() => {
    if (recordRef.current) {
      if (recordRef.current.isPaused()) {
        recordRef.current.resumeRecording();
        setIsRecordingPaused(false);
        return;
      }
      recordRef.current?.startRecording().then(() => {
        setIsPlaying(false);

        handleChangeCount('reset');
        clearCanvas();
      });
    }
  }, [clearCanvas, handleChangeCount]);

  useEffect(() => {
    if (waveSurferRef?.current?.getMediaElement()?.src) {
      return;
    }
    const audioSource = test?.audio || audio;

    if (waveRef.current && audioSource) {
      const recordedUrl = URL.createObjectURL(audioSource);
      if (!waveSurferRef.current) {
        initWaveSurfer(waveRef.current, recordedUrl);
      } else {
        waveSurferRef.current.load(recordedUrl);
      }
    }
  }, [audio, test?.audio, initWaveSurfer]);

  return (
    <Box mt={2} mb={3}>
      <Wrap>
        <ControlWrap>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {!isDetail && (
              <>
                <IconButton onClick={isRecording ? handleRecordPause : handleRecord}>
                  {isRecording ? <IconPlaying /> : <IconRecording />}
                </IconButton>
                {isRecording || isRecordingPaused ? (
                  <IconButton onClick={handleRecordStop}>
                    <IconPause />
                  </IconButton>
                ) : null}
              </>
            )}

            {!(isRecording || isRecordingPaused) && (audio || test?.audio) ? (
              <IconButton onClick={handlePlay}>
                {isPlaying ? <IconPlaying /> : <IconPlay />}
              </IconButton>
            ) : null}

            {waveSurferRef?.current?.getMediaElement()?.src && (
              <a
                href={waveSurferRef?.current?.getMediaElement()?.src}
                download={`${patient?.name}_${new Date().getTime()}.webm`}
              >
                <DownloadForOfflineIcon
                  sx={{ width: '34px', height: '34px', color: '#727273' }}
                />
              </a>
            )}
            {isDetail && !audio ? null : <Timer count={timeCount ?? 0} />}
          </Box>
          {!isDetail && (
            <Stack gap={1} direction="row" alignItems="center">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => handleChangeCount('sub')}
                disabled={!count}
              >
                -
              </Button>
              <Box
                sx={{
                  border: '1px solid #746b6b',
                  borderRadius: '57%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '42px',
                  padding: '5px',
                }}
              >
                {count}
              </Box>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => handleChangeCount('add')}
              >
                +
              </Button>
            </Stack>
          )}
        </ControlWrap>
        {isDetail && !audio && !test?.voiceRecordingUrl ? (
          '녹음된 음성파일이 없습니다.'
        ) : (
          <Box sx={{ position: 'relative' }}>
            <WaveContainer ref={waveRef}> {rendered()}</WaveContainer>
          </Box>
        )}
      </Wrap>
      {frequency && usePitch && (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Stack
            sx={{
              background: 'rgba(0, 0, 0, 0.7)',
              color: '#fff',
              padding: 1,
              width: 'max-content',
              borderRadius: '4px',
            }}
          >
            <Typography fontSize="0.8rem"> {`Min: ${frequency?.min}Hz`}</Typography>
            <Typography fontSize="0.8rem">{`Max: ${frequency?.max}Hz`}</Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
