import dayjs, { Dayjs } from 'dayjs';

export const getAge = (date: Dayjs) => {
  const today = dayjs();
  const year = date.year();
  const month = date.month();
  const day = date.date();

  let currentAge = today.year() - year;
  const m = today.month() - month;
  if (m < 0 || (m === 0 && today.date() < day)) {
    currentAge--;
  }
  return currentAge;
};

export const countHangulSyllables = (text: string) => {
  // 한글 유니코드 범위: AC00-D7A3
  const hangulStart = 0xac00;
  const hangulEnd = 0xd7a3;

  let syllableCount = 0;

  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);
    if (code >= hangulStart && code <= hangulEnd) {
      syllableCount++;
    }
  }

  return syllableCount;
};
